body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  scrollbar-color: #bbc9df #ffffff;
  scrollbar-width: thin;
  letter-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px 6px rgba(187, 201, 223, 1);
  border: solid 2px transparent;
  border-radius: 5px;
}

/*start cookies banner*/

.root__1VdHR,
.bottom__1lPpV {
  box-shadow: 0px 2px 10px rgba(46, 96, 170, 0.25);
}

.root__3JpN3:hover,
.root__3HM5t:hover {
  box-shadow: 0px 0px 15px #f16152;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.root__3JpN3,
.root__3HM5t {
  transition: 0.3s;
}

.root__3pu1t.s14__T1muA {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.root__3pu1t.w700__2hDqi {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

/*end cookies banner*/

.gist-messenger-bubble-iframe {
  display: none;
}
